import React, { useState } from 'react';

type LoginProps = {
  handleLoginZalo: (...args: any[]) => void;
};

export default function Login(props: LoginProps) {
  const { handleLoginZalo } = props;
  const [isChecked, setIsChecked] = useState(false);

  const handleOnchangeCheckbox = (e: any) => {
    setIsChecked(e.target.checked);
  }

  const openConsentForm = (type: string) => {
    window.ZGS.Ui.openConsentForm(type);
  }

  return (
    <div className='vltk-login'>
      <div className='vltk-login__menu-desc' />

      <div className='vltk-login__list'>
        <button id='btn-login' className='vltk-login__btn-zalo' disabled={!isChecked} onClick={handleLoginZalo} style={isChecked ? {} : { filter: 'grayscale(100%)', cursor: 'unset' }} />
      </div>

      <div className="vltk-consent">
        <p>Dữ liệu ảnh và tên đại diện bạn cung cấp sẽ được dùng để nâng cao chất lượng dịch vụ. Bạn có thể thay đổi lựa chọn bất kỳ lúc nào.</p>
        <div className="vltk-consent_agreement">
          <input type="checkbox" defaultChecked={isChecked} onChange={handleOnchangeCheckbox} />
          <p>Tôi đồng ý với <a onClick={() => openConsentForm("term")}>điều khoản sử dụng</a> và <a onClick={() => openConsentForm("policy")}>chính sách bảo mật</a></p>
        </div>
      </div>
    </div>
  );
}
