import React from 'react';
import Link from './Link';

const MetaLinks = (props: any) => {
  const { id: userId, name, handleLogoutZalo } = props;

  const meta = !userId
    ? []
    : [
        { title: 'User Id', label: userId, href: '' },
        { title: 'User Name', label: name, href: '' },
        // { title: 'Logout', label: 'Thoát', href: '' },
      ];

  return (
    <ul className='vltk-meta'>
      {meta.map((item, i) => (
        <li key={i}>
          {/* <Link title={item.title} href={item.href} label={item.label} /> */}
          <span>{item.label}</span>
        </li>
      ))}
      {userId && (
        <li>
          <button className='vltk-meta__logout-btn' onClick={handleLogoutZalo}>
            Thoát
          </button>
        </li>
      )}
    </ul>
  );
};

export default MetaLinks;
