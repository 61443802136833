import React from 'react';

const Header = () => {
  return (
    <div className='vltk-header'>
      <div style={{ display: 'inline-flex' }}>
        <div className='vltk-header__game_logo' />
      </div>
    </div>
  );
};

export default Header;
