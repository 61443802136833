const userAgent = navigator.userAgent;

function userAgentMatch(regex: RegExp): boolean {
  return userAgent.match(regex) !== null;
}

// function lazyUserAgentMatch(regex) {
//   return function() {
//     return userAgentMatch(regex);
//   };
// }

const lazyUserAgentMatch = (regex: RegExp) => () => userAgentMatch(regex);

/**
 * Check if browser is Firefox or not
 *
 * @return {boolean}
 */
export const isFF = lazyUserAgentMatch(/gecko\//i);

/**
 * Check if browser is IE Trident or not
 *
 * @return {boolean}
 */
export const isIETrident = lazyUserAgentMatch(/trident\/.+rv:\s*11/i);

/**
 * Check if browser is IPod or not
 *
 * @return {boolean}
 */
export const isIPod = lazyUserAgentMatch(/iP(hone|od)/i);

/**
 * Check if browser is IPad or not
 *
 * @return {boolean}
 */
export const isIPad = lazyUserAgentMatch(/iPad/i);

/**
 * Check if browser is OSX or not
 *
 * @return {boolean}
 */
export const isOSX = lazyUserAgentMatch(/Macintosh/i);
// Check for Facebook App Version to see if it's Facebook
export const isFacebook = lazyUserAgentMatch(/FBAV/i);

export const isZalo = lazyUserAgentMatch(/Zalo/i);

/**
 * Check if browser is Edge or not
 *
 * @return {boolean}
 */
export function isEdge(): boolean {
  return userAgentMatch(/\sEdge\/\d+/i);
}

/**
 * Check if browser is MS IE or not
 *
 * @return {boolean}
 */
export function isMSIE(): boolean {
  return userAgentMatch(/msie/i);
}

/**
 * Check if browser is Chrome or not
 *
 * @return {boolean}
 */
export function isChrome(): boolean {
  return (
    userAgentMatch(/\s(?:(?:Headless)?Chrome|CriOS)\//i) &&
    !isEdge() &&
    !userAgentMatch(/UCBrowser/i)
  );
}

/**
 * Check if browser is IE or not
 *
 * @return {boolean}
 */
export function isIE(): boolean {
  return isEdge() || isIETrident() || isMSIE();
}

/**
 * Check if browser is Safari or not
 *
 * @return {boolean}
 */
export function isSafari(): boolean {
  return (
    userAgentMatch(/safari/i) &&
    !userAgentMatch(/(?:Chrome|CriOS|chromium|android|phantom)/i)
  );
}

/**
 * Check if browser is IOS or not
 *
 * @return {boolean}
 */
export function isIOS(): boolean {
  return userAgentMatch(/iP(hone|ad|od)/i);
}

/**
 * Check if browser is Android or not
 *
 * @return {boolean}
 */
export function isAndroid(): boolean {
  return userAgentMatch(/Android/i) && !userAgentMatch(/Windows Phone/i);
}

export function isAndroidNative() {
  // Android Browser appears to include a user-agent string for Chrome/18
  if (
    userAgentMatch(/chrome\/[123456789]/i) &&
    !userAgentMatch(/chrome\/18/i) &&
    !isFF()
  ) {
    return false;
  }
  return isAndroid();
}

export function isMobile(): boolean {
  return isIOS() || isAndroid() || userAgentMatch(/Windows Phone/i);
}

export function isIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
