/* global ZGS */

import React from 'react';

type IframeProps = {
  src: string;
};

export default function IframeGame(props: IframeProps) {
  const { src } = props;

  // React.useEffect(() => {
  //   window.ZGS.Ui.setSocialButtonActive(true, {
  //     elementId: 'vltk-id-button-social',
  //   });

  //   return () => {
  //     window.ZGS.Ui.setSocialButtonActive(false);
  //   };
  // });

  // React.useEffect(() => {
  //   // auto follow OA
  //   window.ZGS.registerToFollowOA();
  // }, []);

  return (
    <iframe
      id='vltk-id-ifrgame'
      className='vltk-ifr-game'
      src={src}
      title='vltk'></iframe>
  );
}
