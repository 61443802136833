/* eslint-disable react-hooks/exhaustive-deps */
/* global ZGS */

import React from "react";
import "./styles.css";
import Menu from "./components/Menu";
import Login from "./components/Login";
// import Servers from './components/Servers';
import MetaLinks from "./components/MetaLinks";
import IframeGame from "./components/IframeGame";
import Spinner from "./components/Spinner";
import Servers from "./components/Servers";
import Alert from "./components/Alert";
import {
  greaterThanXDay,
  isCurrentDay,
  isWebviewZalo,
  openLink,
} from "./utils/helpers";
import { isZalo } from "./utils/browser";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ModalUserInfo from "./components/ModalUserInfo";
import HttpHelper from "./utils/http";

declare global {
  interface Window {
    ZGS: any;
    ZJSBridge: any;
    opera: any;
    ZagooSupport: any;
  }
}

type ServerInfo = {
  title: string;
  label: string;
  href: string;
};

function App() {
  // const zgs = window.ZGS;
  // const zgcs = window.ZagooSupport;
  // const helpers = zgs.utils.helpers;
  const [sdkInstant, setSdkInstant] = React.useState(false);

  const [autoLogin, setAutoLogin] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState(null);
  const [iframeSrc, setIframeSrc] = React.useState("");

  const [servers, setServers] = React.useState<ServerInfo[]>([]);
  const [serverMessage, setServerMessage] = React.useState("");

  const [requesting, setRequesting] = React.useState(false);
  const [loginError, setLoginError] = React.useState<Error | null>(null);
  const [clientConfig, setClientConfig] = React.useState<{
    [key: string]: any;
  }>({});
  const [requiredUserInfo, setRequiredUserInfo] = React.useState(null);

  // function request(path: any, params: any, isPOST: any) {
  //   return new Promise((resolve, reject) => {
  //     if (isPOST) {
  //       HttpHelper.postFormDataWithoutCredentials(path, {
  //         zgsk: zgs.getZgsk(),
  //         ...params,
  //       })
  //         .then((data) => {
  //           setTimeout(() => {}, 200);
  //           resolve(data);
  //         })
  //         .catch((err) => reject(err));
  //     } else {
  //       HttpHelper.getJSONWithoutCredentials(path, {
  //         zgsk: zgs.getZgsk(),
  //         ...params,
  //       })
  //         .then((data) => {
  //           setTimeout(() => {}, 200);
  //           resolve(data);
  //         })
  //         .catch((err) => reject(err));
  //     }
  //   });
  // }

  // function pullRequiredUserInfo() {
  //   // get local storage, neu co variable, va user chua nhap info, trong ngay chua goi
  //   let lastDate = localStorage.getItem('lastDate');
  //   let isShowPopup = JSON.parse(localStorage.getItem('isShowPopup') || 'false');

  //   if (lastDate == null || isShowPopup) {
  //     request('https://api-v2.zingplay.me/user/get-authentication-info', {}, false).then((resp: any) => {
  //       const { err, msg, data } = resp;
  //       const requiredUserInfo = { ...resp.data };
  //       if (err >= 0) {
  //         setRequiredUserInfo(requiredUserInfo);
  //       }

  //       if (!requiredUserInfo || Object.keys(requiredUserInfo).length === 0) {
  //         localStorage.setItem('isShowPopup', JSON.stringify(true))
  //         localStorage.setItem('lastDate', JSON.stringify(new Date().getTime()))
  //         if (lastDate == null) {
  //           localStorage.setItem('showPopupNow', JSON.stringify(true))
  //         }
  //       } else {
  //         localStorage.setItem('isShowPopup', JSON.stringify(false))
  //       }
  //     });
  //   }
  // }


  // setIframeSrc("https://zbishopgreen.vltkh5.zing.vn:7074/login?channel=h5zl&timestamp=1733309703632&uid=zl4046950984392012939&utm_campaign=eventsinhnhat3-choingay&utm_medium=Website-ldp&utm_source=Website&sign=82eef2f2da75b24b78fe3a410d19f114")

  React.useEffect(() => {
    calculateScale();

    window.ZGS.initialize({
        REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
        APP_ID: process.env.REACT_APP_ID,
        API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
        APP_LOGO:
          "https://zalo.vltkh5.zing.vn/login/static/media/nmvl-logo.00c1713b.png",

      });

      const code = window.ZGS.utils.helpers.getUrlParam("code");
      if (code || isZalo()) {
        setAutoLogin(true);
      }

    

    // const zgsScript = document.createElement("script");
    // // const zsScript = document.createElement("script");
    // const link = document.createElement("link");
    // fetch(
    //   `https://api-v2.zingplay.me/login/client-config-v2?appId=2538253094251826655&zgua=web-pc`
    // )
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     const extraInfo = JSON.parse(data.data.web.extraInfo);
    //     // const zsversion = extraInfo.zagoo_support.version;
    //     const zgversion = extraInfo.sdk_support.version;
    //     const envSDK =
    //       process.env.REACT_APP_ENV === "development"
    //         ? "dev"
    //         : process.env.REACT_APP_ENV === "production"
    //         ? "prod"
    //         : "stg";

    //     link.type = "text/css";
    //     link.rel = "stylesheet";
    //     link.href = `https://h5.zadn.vn/sdk/${envSDK}/zgsdk-styles.2.12.9.css`;
    //     document.head.appendChild(link);

    //     zgsScript.src = `https://h5.zadn.vn/sdk/${envSDK}/zgsdk.2.12.9.min.js`;
    //     zgsScript.type = "text/javascript";
    //     zgsScript.async = false;
    //     document.body.appendChild(zgsScript);
    //     zgsScript.onload = () => {
          
    //     };
    //   });
  }, []);

  const handleLoginZalo = () => {
    if (requesting) {
      return;
    }
    if (window.ZGS) {
      const code = window.ZGS.utils.helpers.getUrlParam("code");
      if (!code) {
        const errorCode = window.ZGS.utils.helpers.getUrlParam("error");
        if (autoLogin && errorCode && errorCode === "-14006") {
          setAutoLogin(false);
          return;
        }
      }
      setRequesting(true);
      window.ZGS
        .loginZalo()
        .then((resp: any) => {
          if (window.ZGS.getZgsk()) {
            
            const env = process.env.REACT_APP_ENV
            const token = window.ZGS.getZgsk();
            if(window.ZagooSupport) {
              window.ZagooSupport.init(
                {env, token}
              );
              console.log("start check PII")
              window.ZagooSupport.checkPII(({reason}: any)=> {
                console.log("done check PII")
                setRequesting(false);
                setUserInfo({ ...resp });
              })
            }else {
              console.log("support sdk fail to init")
            }
          }else {
            console.log("empty token");
          }
        })
        .catch((err: Error) => {
          console.log(err)
          setRequesting(false);
          setLoginError(err);
          window.setTimeout(() => {
            setLoginError(null);
          }, 10000);
        });
    }else {
      console.log("sdk instant not available");
    }
    
  };

  React.useEffect(() => {
    // config left button
    window.ZJSBridge.H5.configLeftButton({
      buttonType: 1,
      dataConfig: {
        confirmToExit: 2,
        dialogMessage: "",
      },
    });
  }, []);

  React.useEffect(() => {
    window.ZJSBridge.onCloseWebview(() => {
      const { groupInviteLink = "" } = clientConfig;
      if (groupInviteLink) {
        window.ZJSBridge.Zalo.openInApp(groupInviteLink, () => {});
      }

      window.ZJSBridge.H5.closeWebview();
    });
  }, [clientConfig]);

  function calculateScale() {
    const { innerWidth, innerHeight } = window;

    console.log(
      "calculateScale :: innerWidth: ",
      innerWidth,
      ", innerHeight: ",
      innerHeight
    );

    const DESIGN_WIDTH = 768;
    const DESIGN_HEIGHT = 1368;

    const scaleH = innerHeight / DESIGN_HEIGHT;
    const scaleW = innerWidth / DESIGN_WIDTH;
    let scale = Math.min(scaleW, scaleH);
    // let scale = Math.min(scaleH, 1);

    const r = document.querySelector(":root") as HTMLElement;
    // console.log(r);
    r.style.setProperty("--scale", `${scale}`);
    r.style.setProperty(
      "--content-scale",
      `${Math.min(1, Math.min(scaleW, scaleH))}`
    );
  }


  React.useEffect(() => {
    if (autoLogin) {
      handleLoginZalo();
    }
  }, [autoLogin]);

  // React.useEffect(() => {
  //   const script = document.createElement("script");

  //   fetch(
  //     "https://api-v2.zingplay.me/login/client-config-v2?appId=199450332601884042&zgua=web-pc"
  //   )
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       const extraInfo = JSON.parse(data.data.web.extraInfo);
  //       const version = extraInfo.zagoo_support.version;
  //       script.src = `https://zagoo.zadn.vn/sdk/zagoo-support.${version}.js`;
  //       script.type = "text/javascript";
  //       script.async = false;
  //       document.body.appendChild(script);                  
  //     });


  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  React.useEffect(() => {
    if (!userInfo) {
      return;
    }
    if( window.ZGS){
      window.ZGS.getClientConfig().then((resp: any) => {
        const { err, data } = resp;
        if (err >= 0) {
          console.log("group url: ", data);
  
          setClientConfig(data);
        }
      });
  
      window.ZGS
        .getGreenServers()
        .then((resp: any) => {
          const list: any[] = resp.list;
          const infos = list.map((info) => {
            const { name, link } = info;
            return { title: name, href: link, label: name };
          });
  
          setServers(infos);
        })
        .catch((err: Error) => {
          console.log("getServerError: ", err);
          setServers([]);
          setServerMessage(err.message);
        });
    }
    
  }, [userInfo]);

  const handlePickServer = (link: string) => {
    setIframeSrc(link);
  };

  const handleLogoutZalo = () => {
    if(window.ZGS){
      window.ZGS
      .logoutZalo()
      .then(() => {
        setUserInfo(null);
      })
      .catch((err: Error) => {
        console.error(err);
      });
    }
  };

  return !iframeSrc ? (
    <div className="vltk-main">
      <div className="vltk-header__adult_logo" />
      <Header />

      {/* <ModalUserInfo setShow={true} /> */}
      <div className="vltk-container">
        <>
          {userInfo && (
            <MetaLinks
              {...(userInfo as any)}
              handleLogoutZalo={handleLogoutZalo}
            />
          )}
          {/* <Menu /> */}
          {!userInfo ? (
            <Login handleLoginZalo={handleLoginZalo} />
          ) : servers.length <= 0 && !serverMessage ? (
            <Spinner />
          ) : servers.length > 1 || serverMessage ? (
            <Servers
              servers={servers}
              onClick={handlePickServer}
              message={serverMessage}
            />
          ) : (
            handlePickServer(servers[0].href)
          )}
        </>
      </div>

      {/* <Footer /> */}

      {/* {loginError !== null && (
        <Alert
          severity='error'
          message='Đăng nhập không thành công. Vui lòng thử lại sau!'
        />
      )} */}
    </div>
  ) : (
    <>
      <IframeGame src={iframeSrc} />
      <div className="vltk-button-social" id="vltk-id-button-social" />
      {/* <button onClick={handleTestPayment}>Test Payment </button> */}
    </>
  );
}

export default App;
