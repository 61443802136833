import React from 'react';

type ServerInfo = {
  title: string;
  label: string;
  href: string;
};

type ServersProps = {
  servers: ServerInfo[];
  onClick: (...args: any[]) => any;
  message?: string;
};

const Servers = (props: ServersProps) => {
  const { servers = [], onClick, message = '' } = props;

  const handlePickServer = (link: string) => {
    if (onClick) {
      onClick(link);
    }
  };

  return (
    <div className='vltk-server'>
      {message && servers.length > 0 ? (
        <>
          <h2>Chọn máy chủ</h2>

          <ul className='vltk-server__list'>
            {servers.map((info, i) => (
              <li className='vltk-server__list-item' key={i}>
                <button
                  className='vltk-server__list-item--link'
                  onClick={() => {
                    handlePickServer(info.href);
                  }}>
                  {info.label}
                </button>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p className='vltk-server__message'>{message}</p>
      )}
    </div>
  );
};
export default Servers;
